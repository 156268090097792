<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header">
            <div class="content">
              <h2>
                Trials
                <span class="tag is-danger">SUPERUSER</span>
              </h2>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <section class="notification">
              <div class="columns">
                <div class="column is-4">
                  <div class="content">
                    <h2>Country</h2>
                  </div>
                  <b-checkbox v-model="showCountrySelect" :true-value="false" :false-value="true">
                    All Countries
                  </b-checkbox>
                  <b-checkbox v-model="showCountrySelect" :true-value="true" :false-value="false">
                    Filter
                  </b-checkbox>
                  <b-select v-if="countriesData && showCountrySelect" v-model="selectedCountries" multiple>
                    <option v-for="country in countriesData" :key="country.code" :value="country.code">
                      {{ country.name }}
                    </option>
                  </b-select>
                </div>
                <div class="column is-4">
                  <div class="content">
                    <h2>Product</h2>
                    <b-checkbox v-model="spelling">
                      Spelling
                    </b-checkbox>
                    <b-checkbox v-model="number">
                      Maths
                    </b-checkbox>
                    <b-checkbox v-model="phonics">
                      Phonics
                    </b-checkbox>
                    <b-checkbox v-model="literacy">
                      Literacy
                    </b-checkbox>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="content">
                    <h2>Time Range</h2>
                    <b-field label="From" horizontal>
                      <b-datepicker v-model="from" />
                    </b-field>
                    <b-field label="To" horizontal>
                      <b-datepicker v-model="to" />
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="has-text-centered">
                <button class="button is-link" @click="page = 1; getTrialsData();">
                  Search
                </button>
              </div>
            </section>
            <!-- <section id="chart" class="section">
              CHART
            </section> -->
            <section class="section">
              <div class="content">
                <h2>TOTAL: {{ total }} Trials</h2>
              </div>
              <b-table
                v-if="trialsData"
                :data.sync="trialsData"
                :paginated="true"
                :backend-pagination="true"
                per-page="100"
                :total="total"
                :sort="false"
                :mobile-cards="true"
                :striped="true"
                :selectable="false"
                default-sort-direction="DESC"
                default-sort="id"
                @page-change="onPageChange"
              >
                <template slot-scope="props">
                  <b-table-column field="id" label="School ID">
                    <p>
                      <router-link :to="'/subscriptions?q=' + props.row.school_id">
                        {{ props.row.school_name }} (#{{ props.row.school_id }})
                      </router-link> <span class="tag is-info">{{ props.row.org_type.toUpperCase() }}</span>
                    </p>
                    <p>{{ props.row.country_name }}</p>
                    <figure v-if="props.row.clever_oid" class="figure">
                      <img src="/img/clevericon.png" style="width: 32px;">
                    </figure>
                  </b-table-column>
                  <b-table-column field="name" label="User">
                    <p>{{ props.row.name }}</p>
                    <p><a :href="'mailto:' + props.row.email">{{ props.row.email }}</a></p>
                  </b-table-column>
                  <b-table-column field="creation_timestamp" label="Creation Date">
                    {{ formattedDate(props.row.creation_timestamp) }}
                  </b-table-column>
                  <b-table-column field="creation_timestamp" label="Expiry">
                    {{ formattedDate(props.row.expiry) }}
                  </b-table-column>
                  <b-table-column>
                    <div class="tags">
                      <span v-if="props.row.spelling" class="tag is-warning">SPELLING</span>
                      <span v-if="props.row.number" class="tag is-number">MATHS</span>
                      <span v-if="props.row.phonics" class="tag is-phonics">PHONICS</span>
                      <!-- <span v-if="props.row.group === 'litshed'" class="tag is-dark">LITERACY</span> -->
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered content">
                      <p>
                        <b-icon
                          custom-class="far"
                          pack="fa"
                          icon="frown"
                          size="is-large"
                        />
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import moment from 'moment'
import { request } from '@/edshed-common/api'

export default {
  name: 'Trials',
  data () {
    return {
      showCountrySelect: false,
      countriesData: null,
      selectedCountries: [''],
      spelling: true,
      number: true,
      phonics: false,
      literacy: false,
      trialsData: null,
      from: null,
      to: null,
      page: 1,
      total: 0
    }
  },
  mounted () {
    this.loadCountries()
    this.to = new Date()
    this.from = new Date()
    this.from.setDate(this.from.getDate() - 30)
    this.getTrialsData()
  },
  methods: {
    loadCountries () {
      // var that = this
      request('GET', 'countries', null)
        .then((response) => {
          const data = response.data
          this.countriesData = data.countries
          // console.log('readerData: ' + this.readerData)
          if (!this.countriesData || this.countriesData.length === 0) {
            this.response = 'No Countries'
            console.warn('No countries returned from the API! This could mean no seed data has been imported.')
          } else {
            // this.selectedCountries = this.countriesData.map(c => c.code)
          }
        })
        .catch((error) => {
          console.log(error)

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getTrialsData () {
      // var that = this
      let countries = '*'
      if (this.showCountrySelect) {
        countries = this.selectedCountries.join(',')
      }
      const { spelling, number, from, to } = this
      request('GET', 'superuser/subscriptions/trials', { countries, spelling: (spelling ? 1 : 0), number: (number ? 1 : 0), phonics: (this.phonics ? 1 : 0), literacy: (this.literacy ? 1 : 0), from: this.systemDateFormatted(from), to: this.systemDateFormatted(to), page: this.page - 1 })
        .then((response) => {
          const data = response.data
          this.trialsData = data.trials
          this.total = data.count
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    onPageChange (page) {
      this.page = page
      this.getTrialsData()
    },
    formattedDate (d) {
      return moment(d).format('DD/MM/YYYY')
    },
    systemDateFormatted (d) {
      return moment(d).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
